
.parallax {
  /* The image used */
  background-image: url('../images/blog-head.jpg');

  /* Set a specific height */
  height: 500px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.ltn__breadcrumb-area {
    position: relative;
    padding: 260px 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  
    .overlay {
      position: absolute; 
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5); // Change this color and opacity as needed
      z-index: 1;
    }
  
    &.bg-overlay-white-30 {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.3);
        z-index: 2;
      }
    }
  
    .container {
      position: relative;
      z-index: 3;
    }
  
    .ltn__breadcrumb-inner {
      text-align: left;
  
      .page-title {
        font-size: 48px;
        font-weight: 700;
        margin-bottom: 20px;
        color: #fff;
      }
  
      .ltn__breadcrumb-list {
        ul {
          display: flex;
          flex-wrap: wrap;
          list-style: none;
          padding: 0;
          margin: 0;
  
          li {
            margin-right: 10px;
  
            a {
              color: #fff;
              transition: color 0.3s ease;
              display: flex;
              align-items: center;
  
              .ltn__secondary-color {
                margin-right: 5px;
                display: flex;
                align-items: center;
              }
  
              i {
                font-size: 14px;
              }
  
              &:hover {
                color: #007bff;
              }
            }
  
            &:not(:last-child)::after {
              content: '/';
              margin-left: 10px;
              color: #fff;
            }
          }
        }
      }
    }
  }
  