@import './vars.scss';

#home-page{
  .layout {
    display: flex;
    flex-wrap: wrap;
  }
  #service{
    padding: 9em 0 4em 0;
    background-color: #f5f5f5;
    //background: rgba(255,255,255,0.9) url("../../assets/images/service4.jpg") no-repeat;
    //background-size: cover;
    //background-attachment: fixed;
    //background-blend-mode: lighten;
    .service-c {
      display: flex;
      margin-bottom: 30px;
      a {
        flex: 1;
        display: flex;
      }
    }
    .service-widget{
      flex: 1;
      display: flex;
      flex-direction: column;
      background-color: #ffffff;
      position: relative;
      box-shadow: 0 0 6px rgba($color: #000000, $alpha: 0.4);
      .service-image{
        min-height: 250px;
        background-color:rgba($color: #000000, $alpha: 0.2);
        background-position: center;
        background-size: cover;
        transition: all 500ms ease;
      }
      &:hover{
        .service-image{
          min-height: 230px;
          background-blend-mode: overlay;
          transition: all 500ms ease;
        }
        .more-button{
          color: #ffffff;
          background: $theme-color;
          border-color:$theme-color;
        }
      }
      .service-details{
        flex: 1;
        //padding: 20px 20px 39px;
        padding: 20px;
        display: flex;
        align-items: center;
        > div {
          width: 100%;
        }
        h3{
          text-transform: uppercase;
          color: $theme-color;
          font-size: 1.2em;
          font-weight: bold;

          margin-top: 0;
        }
        p {
          margin-bottom: 0;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          color: #494949;

           /* Standard property */
            line-clamp: 3;
        }
      }
      .more-button{
        border-radius: 0;
        display: inline-block;
        margin-top: 15px;
        background: white;
        border: 1px solid $theme-color;
        color: $theme-color;
      }
    }
  }
}
