.ltn__blog-area {
    margin-bottom: 120px;
    margin-top: 5%;
  
    .container {
      .row {
        .col-lg-8 {
          .ltn__blog-list-wrap {
            .ltn__blog-item {
              margin-bottom: 30px;
              background: #fff;
              border: 2px solid #ddd;
              //transition: transform 0.3s ease, box-shadow 0.3s ease;
  
              // &:hover {
              //   transform: scale(1.05);
              //   box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
              // }
  
              .ltn__blog-img,
              .ltn__video-img,
              .ltn__blog-gallery-active,
              .post-audio {
                img {
                  width: 100%;
                }
  
                .ltn__video-icon-2 {
                  display: block;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  background: rgba(255, 255, 255, 0.7);
                  border-radius: 50%;
                  padding: 10px;
                  //transition: background 0.3s ease;
  
                  // &:hover {
                  //   background: rgba(255, 255, 255, 1);
                  // }
                }
              }
  
              .ltn__blog-brief {
                padding: 20px;
  
                .ltn__blog-meta {
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 10px;
  
                  ul {
                    display: flex;
                    list-style: none;
                    padding: 0;
                    margin: 0;
  
                    li {
                      margin-right: 15px;
  
                      a {
                        color: #333;
                        //transition: color 0.3s ease;
  
                        // &:hover {
                        //   color: #007bff;
                        // }
  
                        i {
                          margin-right: 5px;
                        }
                      }
  
                      &.ltn__blog-date {
                        color: #888;
                      }
                    }
                  }
                }
  
                h3.ltn__blog-title {
                  font-size: 20px;
                  margin: 0 0 10px;
  
                  a {
                    color: #333;
                    //transition: color 0.3s ease;
  
                    // &:hover {
                    //   color: #007bff;
                    // }
                  }
                }
  
                p {
                  margin-bottom: 10px;
                  color: #666;
                }
  
                .ltn__blog-meta-btn {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
  
                  .ltn__blog-author {
                    display: flex;
                    align-items: center;
  
                    img {
                      width: 40px;
                      height: 40px;
                      border-radius: 50%;
                      margin-right: 10px;
                    }
  
                    a {
                      color: #333;
                      //transition: color 0.3s ease;
  
                      // &:hover {
                      //   color: #007bff;
                      // }
                    }
                  }
  
                  .ltn__blog-btn {
                    a {
                      color: #007bff;
                      //transition: color 0.3s ease;
  
                      // &:hover {
                      //   color: #0056b3;
                      // }
  
                      i {
                        margin-left: 5px;
                      }
                    }
                  }
                }
              }
            }
  
            .ltn__blog-item-video {
              position: relative;
            }
  
            .ltn__blog-item-gallery {
              .ltn__blog-gallery-active {
                display: flex;
                overflow: hidden;
                border-radius: 5px 5px 0 0;
  
                .ltn__blog-gallery-item {
                  min-width: 100%;
  
                  img {
                    width: 100%;
                    height: auto;
                    display: block;
                  }
                }
              }
            }
  
            .ltn__blog-item-audio {
              .post-audio {
                margin-bottom: 15px;
              }
            }
  
            .ltn__blog-item-quote {
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
              color: #fff;
  
              .ltn__blog-brief {
                padding: 20px;
  
                blockquote {
                  margin: 0;
                  font-size: 24px;
  
                  a {
                    color: inherit;
                    //transition: color 0.3s ease;
  
                    // &:hover {
                    //   color: #ccc;
                    // }
                  }
                }
              }
  
              .ltn__blog-meta {
                ul {
                  li {
                    a {
                      color: #fff;
                      //transition: color 0.3s ease;
  
                      // &:hover {
                      //   color: #ccc;
                      // }
                    }
                  }
                }
              }
            }
  
            .ltn__blog-item-bg-image {
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
              color: #333;
  
              .ltn__blog-brief {
                padding: 20px;
                background: rgba(255, 255, 255, 0.9);
                border-radius: 0 0 5px 5px;
              }
            }
          }
        }
  
        .col-lg-12 {
          .ltn__pagination-area {
            text-align: center;
  
            .ltn__pagination {
              display: inline-block;
  
              ul {
                list-style: none;
                padding: 0;
                margin: 0;
                display: flex;
  
                li {
                  margin: 0 5px;
  
                  a {
                    display: block;
                    padding: 10px 15px;
                    border: 1px solid #ddd;
                    border-radius: 5px;
                    color: #333;
                    //transition: all 0.3s ease;
  
                    // &:hover {
                    //   background: #007bff;
                    //   color: #fff;
                    //   border-color: #007bff;
                    // }
  
                    &.active {
                      background: #007bff;
                      color: #fff;
                      border-color: #007bff;
                    }
  
                    i {
                      font-size: 16px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  