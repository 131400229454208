@import './vars.scss';

#home-page{
  #home{
    min-height: 100vh;
    position: relative;
    .rslides{
      position: relative;
      list-style: none;
      overflow: hidden;
      width: 100%;
      padding: 0;
      margin: 0;
      li{
        a{
          text-decoration: none;
        }
        -webkit-backface-visibility: hidden;
        position: absolute;
        display: none;
        width: 100%;
        left: 0;
        top: 0;
        /* Standard property */
    backface-visibility: hidden;
        &:first-child {
          position: relative;
          display: block;
          float: left;
        }
        > div {
          display: flex;
          align-items: center;
          // justify-content: center;
          height: auto;
          float: left;
          width: 100%;
          color: #ffffff;
          border: 0;
          min-height: 100vh;
          padding-top: 100px;
          text-align: center;
          text-shadow: 0 2px 8px rgba(0,0,0,.6);
          .btn {
            font-weight: bold;
            //border-width: 2px;
            margin: .5em;
          }
          img{
            width: 450px;
          }
          // .btn {
          //   //box-shadow: 0 0 15px 4px rgba(0,0,0,0.5);
          //   //text-shadow: 2px 2px 8px #131313;
          // }
          &.slide-1 {
            background: rgba(0,0,0,0.2) url("../../assets/images/hbg-2.jpeg");
            background-size: cover;
            background-position: center center;
            background-attachment: fixed;
            background-blend-mode: darken;
          }
          &.slide-2 {
            background: rgba(0,0,0,0.2) url("../../assets/images/ict-cctv.jpg");
            background-size: cover;
            background-position: center left;
            background-blend-mode: darken;
            background-attachment: fixed;
          }
          &.slide-3 {
            background: rgba(0,0,0,0.2) url("../../assets/images/bg-10.jpg");
            background-size: cover;
            background-position: center center;
            background-blend-mode: darken;
            background-attachment: fixed;
          }
        }
      }
    }

    .large-heading{
      font-size: 70px;
      font-weight: 700;
      letter-spacing: 8px;
      @media(max-width:1199px) and (min-width:992px){
        font-size: 58px;
        letter-spacing: 8px;
      }
      @media(max-width:991px) and (min-width:768px){
        font-size: 33px;
        letter-spacing: 7px;
      }
      @media(max-width:576px){
        font-size: 28px;
        letter-spacing: 3px;
      }
    }
    .small-heading{
      white-space: nowrap;
      visibility: inherit;
      text-align: left;
      letter-spacing: -1px;
      font-weight: 700;
      font-size: 40px;
      @media(max-width:1199px) and (min-width:992px){
        font-size: 33px;
      }
      @media(max-width:991px) and (min-width:768px){
        font-size: 25px;
        letter-spacing: 7px;
      }
      @media(max-width:576px) and (min-width:330px){
        font-size: 17px;
        letter-spacing: 3px;
      }
      @media(max-width:329px){
        font-size: 15px;
        letter-spacing: 3px;
      }
    }
    .caption-large{
      font-family: 'PT Sans', sans-serif;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 2px;
      @media(max-width:1199px) and (min-width:992px){
        font-size: 17px;
        letter-spacing: 1px;
      }
      @media(max-width:991px) and (min-width:768px){
        font-size: 12px;
        letter-spacing: 1px;
      }
      @media(max-width:576px){
        font-size: 11px;
      }
    }
    .caption-small{
      font-size: 16px;
      font-family: 'PT Sans';
      // white-space: nowrap;
      text-align: left;
      font-weight: 400;
      letter-spacing: 2px;
      @media(max-width:1199px) and (min-width:992px){
        font-size: 13px;
        letter-spacing: 1px;
      }
      @media(max-width:991px) and (min-width:768px){
        font-size: 10px;
        letter-spacing: 0px;
      }
      @media(max-width:576px) and (min-width:330px){
        font-size: 10px;
        // display: none;
      }
    }
    .home-btn{
      border-radius: 2px;
      color: #ffffff !important;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 1px;
      padding: 13px 18px 15px;
      border-width: 0px;
      margin-right: 10px;
      cursor: pointer;
      @media(max-width:1199px) and (min-width:992px){
        font-size: 11px;
        padding: 11px 15px 12px;
      }
      @media(max-width:991px) and (min-width:768px){
        font-size: 8px;
        padding: 8px 11px 9px;
        letter-spacing: 0px;
      }
      @media(max-width:576px){
        font-size: 8px;
        padding: 7px 8px 8px;
      }
      &.primary-btn{
        background-color: $theme-color;
      }
      &.transparent-btn{
        background: none;
        padding: 11px 18px 13px;
        // border-width: 2px;
        border: 2px solid;
        @media(max-width:1199px) and (min-width:992px){
          padding: 9px 15px 11px;
          border: 2px solid;
        }
        @media(max-width:991px) and (min-width:768px){
          padding: 7px 11px 8px;
          border: 1px solid;
        }
        @media(max-width:576px){
          padding: 6px 8px 6px;
        }
      }
    }
  }
  #about{
    background: url('../../assets/images/prop-head.jpg');
    background-size: cover;
    background-attachment: fixed;
    .container {
      display: flex;
      //.img-c {
      //  display: flex;
      .content {
        padding: 5em;
        background-color: #ffffff;
        //background-color: #f5f5f5;
      }
      @media(max-width:576px){
        padding: 5em 2em;
      }
    }
    .about-description{
      font-family: 'Raleway', sans-serif;
      font-size: 15px;
      color: #4d4d4d;
      line-height: 1.6em;
      text-align: justify;
    }
    .about-widget{
      position: relative;
      min-height: 350px;
      @media(max-width:767px){
        padding-top: 19em;
      }
      &::before{
        @media(max-width:767px){
          right: 0;
          height: 15em;
        }
        left: 0;
        right: 50%;
        bottom: 0;

        background-position: 50%;
        top: 0;
        // right: 0;
        content: "";
        position: absolute;
      }

    }
  }
  .layout {
    display: flex;
    flex-wrap: wrap;
  }

    .service-c {
      display: flex;
      margin-bottom: 30px;
      a {
        flex: 1;
        display: flex;
      }
    }
    .service-widget{
      flex: 1;
      display: flex;
      flex-direction: column;
      background-color: #ffffff;
      position: relative;
      box-shadow: 0 0 6px rgba($color: #000000, $alpha: 0.4);
      .service-image{
        min-height: 250px;
        background-color:rgba($color: #000000, $alpha: 0.2);
        background-position: center;
        background-size: cover;
        transition: all 500ms ease;
      }
      &:hover{
        .service-image{
          min-height: 230px;
          background-blend-mode: overlay;
          transition: all 500ms ease;
        }
        .more-button{
          color: #ffffff;
          background: $theme-color;
          border-color:$theme-color;
        }
      }
      .service-details{
        // flex: 1;
        //padding: 20px 20px 39px;
        padding: 20px;
        display: flex;
        align-items: center;
        > div {
          width: 100%;
        }
        h3{
          text-transform: uppercase;
          color: $theme-color;
          font-size: 1.2em;
          font-weight: bold;

          margin-top: 0;
        }
        p {
          margin-bottom: 0;
          overflow: hidden;
          // display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          color: #494949;
          /* Standard property */
          line-clamp: 3;
        }
      }
      .more-button{
        border-radius: 0;
        display: inline-block;
        margin-top: 15px;
        background: white;
        border: 1px solid $theme-color;
        color: $theme-color;
      }
    }
  
  #client-details{
    padding: 4em 0;
    position: relative;
    background: #ffffff;
    .popular-clients{
      table{
        margin-top: 20px;
        width: 100%;
        margin-bottom: 0;
        border: 1px solid #e9e9e9;
        border-collapse: collapse;
        border-spacing: 0;
        td{
          border: 1px solid #e9e9e9;
          line-height: 1.42857;
          padding: 5px;
          vertical-align: middle;
          text-align: center;
          img{
            width: 100%;
          }
        }
      }
    }
    .client-testimonials{
      .testimonial-content{
        margin-top: 20px;
        font-family: "PT Sans";
        color: #3f3f3f;
        border: 1px solid #e9e9e9;
        padding: 17px 20px 20px 20px;
        border-radius: 2px;
        font-style: italic;
        font-size: 15px;
        line-height: 1.8;
        font-weight: 400;
      }
      .testimonial-header{
        padding: 0px 20px;
        display: block;
        .testimonial-avatar{
          margin-top: -25px;
          float: left;
          margin-right: 10px;
          img{
            width: 60px;
            border-radius: 100%;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
            display: block;
            height: auto;
            max-width: 100%;
            // vertical-align: middle;
          }
        }
        .testimonial-name{
          font-size: 12px;
          margin-top: 8px;
          font-family: Montserrat, sans-serif;
          font-weight: 500;
          //color: #333333;
          color: $theme-color;
        }
      }
    }
  }
}
.innovative {
  text-align: -webkit-center;
  h2 {
    max-width: 60%;
    span {
      color: #9f393b;
    }
  }
}
p {
  strong {
    color:#31708f;
  }
}
.innovative {
  text-align: -webkit-center;
  h1 {
    max-width: 60%;
    span {
      color: #9f393b;
    }
  }
}
p {
  strong {
    color:#31708f;
  }
}





