.sidebar-area {
    .widget {
      margin-bottom: 30px;
      padding: 20px;
      background-color: #fff;
      border: 1px solid #f0f0f0;
    }
  
    .ltn__author-widget {
      text-align: center;
  
      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin-bottom: 15px;
      }
  
      h5 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 5px;
      }
  
      small {
        display: block;
        font-size: 14px;
        color: #777;
        margin-bottom: 15px;
      }
  
      .product-ratting {
        margin-bottom: 10px;
  
        ul {
          display: flex;
          justify-content: center;
  
          li {
            margin: 0 2px;
  
            i {
              color: #f9c922;
            }
  
            &.review-total {
              font-size: 14px;
              color: #777;
            }
          }
        }
      }
  
      .ltn__social-media {
        ul {
          display: flex;
          justify-content: center;
          margin-top: 10px;
  
          li {
            margin: 0 5px;
  
            a {
              font-size: 16px;
              color: #777;
  
              &:hover {
                color: #f9c922;
              }
            }
          }
        }
      }
    }
  
    .ltn__search-widget {
      form {
        display: flex;
  
        input {
          flex: 1;
          padding: 10px;
          border: 1px solid #ddd;
          border-right: none;
  
          &:focus {
            border-color: #f9c922;
          }
        }
  
        button {
          padding: 10px 15px;
          background-color: #f9c922;
          border: none;
          color: #fff;
  
          i {
            font-size: 16px;
          }
        }
      }
    }
  
    .ltn__form-widget {
      form {
        input,
        textarea {
          width: 100%;
          padding: 10px;
          margin-bottom: 10px;
          border: 1px solid #ddd;
  
          &:focus {
            border-color: #f9c922;
          }
        }
  
        button {
          width: 100%;
          padding: 10px;
          background-color: #f9c922;
          border: none;
          color: #fff;
          font-weight: 600;
  
          &:hover {
            background-color: #e5b800;
          }
        }
      }
    }
  
    .ltn__top-rated-product-widget {
      ul {
        li {
          margin-bottom: 15px;
  
          .top-rated-product-item {
            display: flex;
            align-items: center;
  
            .top-rated-product-img {
              flex-shrink: 0;
              width: 70px;
              margin-right: 15px;
  
              img {
                width: 100%;
              }
            }
  
            .top-rated-product-info {
              .product-ratting {
                margin-bottom: 5px;
  
                ul {
                  display: flex;
  
                  li {
                    margin-right: 2px;
  
                    i {
                      color: #f9c922;
                    }
                  }
                }
              }
  
              h6 {
                font-size: 14px;
                margin-bottom: 5px;
                a {
                  color: #333;
  
                  &:hover {
                    color: #f9c922;
                  }
                }
              }
  
              .product-price {
                span {
                  font-size: 14px;
                  color: #f9c922;
                  font-weight: 600;
                  margin-right: 10px;
                }
  
                del {
                  font-size: 12px;
                  color: #999;
                }
              }
            }
          }
        }
      }
    }
  
    .ltn__menu-widget {
      ul {
        li {
          margin-bottom: 10px;
  
          a {
            display: flex;
            justify-content: space-between;
            color: #333;
  
            &:hover {
              color: #f9c922;
            }
          }
        }
      }
    }
  
    .ltn__popular-post-widget {
      ul {
        li {
          margin-bottom: 15px;
          display: block;
  
          .popular-post-widget-item {
            display: flex;
  
            .popular-post-widget-img {
              flex-shrink: 0;
              width: 70px;
              margin-right: 15px;
  
              img {
                width: 100%;
              }
            }
  
            .popular-post-widget-brief {
              h6 {
                font-size: 14px;
                margin-bottom: 5px;
                margin-top: 0px;
  
                a {
                  color: #333;
  
                  &:hover {
                    color: #f9c922;
                  }
                }
              }
  
              .ltn__blog-meta {
                font-size: 12px;
                color: #777;
              }
            }
          }
        }
      }
    }
  
    .ltn__social-media-widget {
      .ltn__social-media-2 {
        ul {
          display: flex;
  
          li {
            margin-right: 10px;
  
            a {
              font-size: 16px;
              color: #333;
  
              &:hover {
                color: #f9c922;
              }
            }
          }
        }
      }
    }
  
    .ltn__tagcloud-widget {
      ul {
        display: flex;
        flex-wrap: wrap;
  
        li {
          margin: 5px;
  
          a {
            display: inline-block;
            padding: 5px 10px;
            background-color: #f9f9f9;
            color: #333;
            border: 1px solid #ddd;
            border-radius: 3px;
  
            &:hover {
              background-color: #f9c922;
              color: #fff;
            }
          }
        }
      }
    }
  
    .ltn__banner-widget {
      display: none;
  
      a {
        img {
          width: 100%;
        }
      }
    }
  }
  .ml-0 {
    margin-left: 0;
  }
  .no-point {
    margin-left: 0 !important;
  }