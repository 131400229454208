@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';

$theme-color:#9f393b;
$theme-color2:#f8f9f9;


$navbar-height : 60px;
$navbar-mini-height : 40px;

$dark-gray:#0b0d0f;
$gray: #19181e;
$gray0: #222127;
$gray1: #333138;
$gray2: #47464d;
$gray3: #676570;
$gray4: #808080;
$gray5: #99999b;
$gray6: #b2b2b4;

$navbar-default-bg: rgba(255,255,255,0.9);
$navbar-default-link-color: $theme-color;
$navbar-default-link-hover-bg: $theme-color;
$navbar-default-link-hover-color: #fff;
$navbar-default-link-active-bg: $theme-color;
$navbar-default-link-active-color: $theme-color;
$navbar-border-radius: 0;

//$navbar-inverse-bg: rgba(0,0,0,0.1);
//$navbar-inverse-link-color: #f9f9f9;
//$navbar-inverse-link-hover-bg: $theme-color;
//$navbar-inverse-link-hover-color: #fff;
//$navbar-inverse-link-active-bg: $theme-color;
//$navbar-inverse-link-active-color: $theme-color;
//$navbar-border-radius: 0;



//** Point at which the navbar becomes uncollapsed.
//$grid-float-breakpoint:     900px ;
$navbar-padding-vertical: ($navbar-height - 20)/2;
//** Point at which the navbar begins collapsing.
//$grid-float-breakpoint-max: ($grid-float-breakpoint - 1);

