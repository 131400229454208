@import './vars.scss';
#top-nav{
  .top-bar{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20;
    font-size: 12px;
    overflow: hidden;
    //background: #f8f9f9;
    background: #354d52;
    //background: $theme-color;
    color: #c4c4c4;
    height: 40px;
    font-weight: 500;
    .container {
      display: flex;
      > div:first-child {
        flex: 1;
      }
      .social {
        a {
          color: #ededed;
          &:hover {
            //color: $theme-color;
            color: #ffffff;
          }
        }
      }
      .contact {
        display: flex;
        a {
          letter-spacing: 0.5px;
          //color: $theme-color;
          color: #f6f6f6;
        }
      }
    }
    .top-widget {
      display: inline-block;
      padding: 12px 12px 12px;
      height: 40px;
      //border-right: 1px solid #e9e9e9;
      border-right: 1px solid rgba(255,255,255,0.2);
      &:first-child {
        //border-left: 1px solid #e9e9e9;
        border-left: 1px solid rgba(255,255,255,0.2);
      }
    }
  }
  nav{
    transition: all 0.5s ease;
    border-bottom: none !important;
    box-shadow: 0 3px 4px -2px rgba(0,0,0,.3);
    &:not(.onScroll){
      top: 40px;
    }
    &.onScroll {
      background-color: rgba(255,255,255,0.95);
      top: 0;
      border-top: 4px solid $theme-color !important;
    }
    .navbar-toggle {
      border: none;
      background-color: $theme-color2;
      .icon-bar {
        background-color: $theme-color;
      }
    }
    .navbar-brand {
      padding: 10px 0 10px 15px;
      img {
        height: 100%;
        //-webkit-filter: drop-shadow(0px 0px 6px  rgba(0,0,0,0.5));
        //filter: drop-shadow(0px 0px 6px  rgba(0,0,0,0.5));
      }
    }
    li {
      a{
        font-weight: 600;
        text-transform: uppercase;
        &:hover,&:focus {
          text-decoration: none;
          transition: all 200ms;
          color: #ffffff;
          background-color: $theme-color;
        }
      }
    }
  }
}