@import './vars.scss';

#home-page{
  
  #about{
    background: url('../../assets/images/prop-head.jpg');
    background-size: cover;
    background-attachment: fixed;
    .container {
      display: flex;
      //.img-c {
      //  display: flex;
      .content {
        padding: 8em 5em 5em 5em;
        background-color: #ffffff;
        //background-color: #f5f5f5;
      }
      @media(max-width:576px){
        padding: 5em 2em;
      }
    }
    .about-description{
      font-family: 'Raleway', sans-serif;
      font-size: 15px;
      color: #4d4d4d;
      line-height: 1.6em;
      text-align: justify;
    }
    .about-widget{
      position: relative;
      min-height: 350px;
      @media(max-width:767px){
        padding-top: 19em;
      }
      &::before{
        @media(max-width:767px){
          right: 0;
          height: 15em;
        }
        left: 0;
        right: 50%;
        bottom: 0;

        background-position: 50%;
        top: 0;
        // right: 0;
        content: "";
        position: absolute;
      }

    }
  }
  .layout {
    display: flex;
    flex-wrap: wrap;
  }
  
}
