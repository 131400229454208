@import './vars.scss';
#contact-page{
  ul{
    line-height: 2;
    margin-top: 28px;
    padding: 0;
    margin: 0;
    li{
      display: block;
      dl{
        color: #888888;
        margin: 0;
        display: block;
        dt{
          display: inline;
          font-weight: inherit;
          line-height: 1.625;
          &::after{
            content: ':';
            margin-right: 5px;
            display: inline-block;
          }
          dd{
            display: inline ;
          }
        }
      }
      a{
       color: $theme-color;
       font-size: 14px;
       line-height: 1.625;
      }
    }
  }
  .contact-header{
    min-height: 40vh;
    background: rgba(0,0,0,0.5) url("../../assets/images/bg-10.jpg");
    background-size: cover;
    background-position: center center;
    background-blend-mode: darken;
    padding-top: 120px ;
    display: flex;
    align-items: center;
    padding-bottom: 20px ;
    h2{
      color: white !important;
    }
  }
  .map-container{
   height: 250px;
  }
  .contact{
    padding: 6em 0;
    .contact-form{
      margin-top: 20px;
      input{
        border: solid 1px #ddd;
        background: #f5f5f5;
        color: #333;
        outline: none;
        padding: 10px;
        margin-bottom: 20px;
        border-radius: 0 !important;
        height: auto;
        width: 100%;
      }
      textarea{
        border: solid 1px #ddd;
        background: #f5f5f5;
        color: #333;
        outline: none;
        width: 100%;
        height: 165px!important;
        padding: 10px;
        margin-bottom: 20px;
      }
    }
    .contact-address{
      margin-top: 15px;
    }
  }
}