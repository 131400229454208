@import './vars.scss';
#footer{
  line-height: 2;
  p{
    margin: 0;
  }
  h1,h2,h3,h4,h5{
    color: $theme-color;
  }
  ul{
    padding: 0;
    margin: 0;
    li{
      display: block;
      dl{
        color: #0b0b0b;
        margin: 0;
        display: block;
        dt{
          display: inline;
          font-weight: inherit;
          line-height: 1.625;
          &::after{
            content: ':';
            margin-right: 5px;
            display: inline-block;
          }
          dd{
            display: inline ;
          }
        }
      }
      a{
       font-size: 14px;
       line-height: 1.625;
        color: #0b0b0b;
        &:hover {
          color: #ffffff;
        }
      }
    }
  }
 .main-footer{
   background: #f2f2f2;
   padding: 5em 0;
   .brand{
     max-width: 100%;
     img{
       width: auto;
       max-width: 100%;
       vertical-align: middle;
     }
   }
   .brand-content{
     margin-top: 20px;
     color: #0b0b0b;
   }
 }
 .sub-footer{
   background: #272727;
   padding: 1em 0;
   color: #cccccc;
   a{
     color: #888888;
   }
   //text-align: right;
   @media (max-width:$screen-sm){
     text-align: center;
   }
 }
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
}