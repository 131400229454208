@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?26778225');
  src: url('../font/fontello.eot?26778225#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?26778225') format('woff2'),
       url('../font/fontello.woff?26778225') format('woff'),
       url('../font/fontello.ttf?26778225') format('truetype'),
       url('../font/fontello.svg?26778225#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?26778225#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-location:before { content: '\e800'; } /* '' */
.icon-comment:before { content: '\e801'; } /* '' */
.icon-ok:before { content: '\e802'; } /* '' */
.icon-ok-circle:before { content: '\e803'; } /* '' */
.icon-mail:before { content: '\e804'; } /* '' */
.icon-mobile:before { content: '\e805'; } /* '' */
.icon-tag:before { content: '\e806'; } /* '' */
.icon-group-circled:before { content: '\e807'; } /* '' */
.icon-group:before { content: '\e808'; } /* '' */
.icon-mail-1:before { content: '\e80a'; } /* '' */
.icon-link:before { content: '\e80b'; } /* '' */
.icon-info-circled:before { content: '\e80c'; } /* '' */
.icon-help-circled:before { content: '\e80d'; } /* '' */
.icon-pencil:before { content: '\e80e'; } /* '' */
.icon-attention:before { content: '\e80f'; } /* '' */
.icon-doc:before { content: '\e810'; } /* '' */
.icon-wrench:before { content: '\e811'; } /* '' */
.icon-cog-alt:before { content: '\e812'; } /* '' */
.icon-cog:before { content: '\e813'; } /* '' */
.icon-clock:before { content: '\e814'; } /* '' */
.icon-off:before { content: '\e815'; } /* '' */
.icon-thumbs-up:before { content: '\e816'; } /* '' */
.icon-down-open:before { content: '\e817'; } /* '' */
.icon-left-open:before { content: '\e819'; } /* '' */
.icon-right-open:before { content: '\e81a'; } /* '' */
.icon-up-open:before { content: '\e81b'; } /* '' */
.icon-truck:before { content: '\e81f'; } /* '' */
.icon-chart-bar:before { content: '\e820'; } /* '' */
.icon-bookmark-empty-1:before { content: '\e821'; } /* '' */
.icon-phone:before { content: '\e822'; } /* '' */
.icon-users:before { content: '\e823'; } /* '' */
.icon-check:before { content: '\e824'; } /* '' */
.icon-thumbs-up-1:before { content: '\e825'; } /* '' */
.icon-check-empty:before { content: '\f096'; } /* '' */
.icon-bookmark-empty:before { content: '\f097'; } /* '' */
.icon-phone-squared:before { content: '\f098'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-docs:before { content: '\f0c5'; } /* '' */
.icon-list-numbered:before { content: '\f0cb'; } /* '' */
.icon-gplus:before { content: '\f0d5'; } /* '' */
.icon-mail-alt:before { content: '\f0e0'; } /* '' */
.icon-linkedin:before { content: '\f0e1'; } /* '' */
.icon-lightbulb:before { content: '\f0eb'; } /* '' */
.icon-doc-text:before { content: '\f0f6'; } /* '' */
.icon-building:before { content: '\f0f7'; } /* '' */
.icon-hospital:before { content: '\f0f8'; } /* '' */
.icon-tablet:before { content: '\f10a'; } /* '' */
.icon-mobile-1:before { content: '\f10b'; } /* '' */
.icon-spinner:before { content: '\f110'; } /* '' */
.icon-help:before { content: '\f128'; } /* '' */
.icon-info:before { content: '\f129'; } /* '' */
.icon-attention-alt:before { content: '\f12a'; } /* '' */
.icon-thumbs-up-alt:before { content: '\f164'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-apple:before { content: '\f179'; } /* '' */
.icon-windows:before { content: '\f17a'; } /* '' */
.icon-skype:before { content: '\f17e'; } /* '' */
.icon-mail-squared:before { content: '\f199'; } /* '' */
.icon-bank:before { content: '\f19c'; } /* '' */
.icon-google:before { content: '\f1a0'; } /* '' */
.icon-fax:before { content: '\f1ac'; } /* '' */
.icon-building-filled:before { content: '\f1ad'; } /* '' */
.icon-file-code:before { content: '\f1c9'; } /* '' */
.icon-history:before { content: '\f1da'; } /* '' */
.icon-chart-area:before { content: '\f1fe'; } /* '' */
.icon-chart-pie:before { content: '\f200'; } /* '' */
.icon-chart-line:before { content: '\f201'; } /* '' */
.icon-bus:before { content: '\f207'; } /* '' */
.icon-diamond:before { content: '\f219'; } /* '' */
.icon-whatsapp:before { content: '\f232'; } /* '' */
.icon-server:before { content: '\f233'; } /* '' */
.icon-balance-scale:before { content: '\f24e'; } /* '' */
.icon-hourglass:before { content: '\f254'; } /* '' */
.icon-television:before { content: '\f26c'; } /* '' */
.icon-calendar-check-o:before { content: '\f274'; } /* '' */
.icon-handshake-o:before { content: '\f2b5'; } /* '' */
.icon-envelope-open:before { content: '\f2b6'; } /* '' */
.icon-envelope-open-o:before { content: '\f2b7'; } /* '' */
.icon-address-card:before { content: '\f2bb'; } /* '' */
.icon-user-circle:before { content: '\f2bd'; } /* '' */
.icon-user-circle-o:before { content: '\f2be'; } /* '' */